import React, { useEffect, useState, useContext, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CardScanView } from '@cardscan.ai/insurance-cardscan-react';
import { setShowRightSidedBannerVisibility, setIsFetching } from '../actions';
import CardscanErrorModal from '../components/CardscanErrorModal';
import useCardScan from '../hooks/useCardScan';
import { CardscanContext } from '../utils/context';
import { TrackInsuranceCardScan } from '../actions/segment';
import { ROUTES } from '../constants/routes';
import { PATIENT_INSURANCE_STATUS } from '../constants/patient';
import usePatientAPI from '../hooks/usePatientAPI';

const CardScanRoute = ({
  setShowRightSidedBannerVisibility,
  setIsFetching,
  TrackInsuranceCardScan,
}) => {
  const [cardScanConfig, setCardScanConfig] = useState({
    token: null,
    error: null,
    skipInsurance: false,
    showErrorModal: false,
  });
  const history = useHistory();
  const { customPatientUpdate } = usePatientAPI();
  const [, setInsuranceInformation] = useContext(CardscanContext);
  const showErrorState = (error) => {
    console.error(error);
    setIsFetching(false);
    setCardScanConfig((prev) => ({ ...prev, error }));
  };
  const { authCardScan, parseCardData, getCard } = useCardScan(() => {},
  showErrorState);

  const onScanSuccess = React.useCallback(
    async (data) => {
      try {
        const card = await getCard(cardScanConfig.token, data.cardId);
        setInsuranceInformation(parseCardData(card));
        setShowRightSidedBannerVisibility(false);
        history.replace('/policy-holder');
      } catch (error) {
        showErrorState('Error retrieving card data');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cardScanConfig.token, history]
  );

  const handleSkipInsuranceInfoClick = async () => {
    setIsFetching(true);
    setCardScanConfig((prev) => ({
      ...prev,
      showErrorModal: false,
      skipInsurance: true,
    }));
    try {
      await customPatientUpdate({
        insurance_card_status: PATIENT_INSURANCE_STATUS.SKIPPED,
      });
      setIsFetching(false);
      setShowRightSidedBannerVisibility(false);
      history.replace(ROUTES.FIRST_APPT_OVERVIEW, { skipInsurance: true });
    } catch (error) {
      console.log('Error updating insurance card status to skipped', error);
      setIsFetching(false);
      setCardScanConfig((prev) => ({ ...prev, skipInsurance: false }));
    }
  };

  const handleCancelInsuranceInfoClick = async () => {
    history.replace(ROUTES.INSURANCE_INFO);
  };

  // Get token for card scan
  useEffect(() => {
    setIsFetching(true);
    setShowRightSidedBannerVisibility(true);

    (async () => {
      const token = await authCardScan();
      if (!token) {
        showErrorState('Could not retrieve cardscan access token');
      } else {
        setCardScanConfig((prev) => ({ ...prev, token }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If either token is set or error modal is shown but insurance flow is not skipped, then show loading spinner
  useEffect(() => {
    if (
      (cardScanConfig.token || cardScanConfig.showErrorModal) &&
      !cardScanConfig.skipInsurance
    ) {
      setIsFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cardScanConfig.token,
    cardScanConfig.skipInsurance,
    cardScanConfig.showErrorModal,
  ]);

  // If card scan error occured, error modal is not shown and insurance flow is not skipped, then show error modal
  // This use effect will prevent to set error modal to true multiple times
  // since error processing through cardscan could trigger a myriad of errors while loading its UI component
  useEffect(() => {
    if (
      cardScanConfig.error &&
      !cardScanConfig.showErrorModal &&
      !cardScanConfig.skipInsurance
    ) {
      setCardScanConfig((prev) => ({ ...prev, showErrorModal: true }));
    }
  }, [
    cardScanConfig.error,
    cardScanConfig.skipInsurance,
    cardScanConfig.showErrorModal,
  ]);

  useLayoutEffect(() => {
    TrackInsuranceCardScan();
    // eslint-disable-next-line
  }, []);

  if (cardScanConfig.showErrorModal) {
    return (
      <CardscanErrorModal
        onSkip={handleSkipInsuranceInfoClick}
        onRetry={handleCancelInsuranceInfoClick}
      />
    );
  }

  return cardScanConfig.token && !cardScanConfig.skipInsurance ? (
    <CardScanView
      live={process.env.REACT_APP_ENV !== 'dev'}
      sessionToken={cardScanConfig.token}
      backsideSupport
      onSuccess={onScanSuccess}
      onError={showErrorState}
      onCancel={handleCancelInsuranceInfoClick}
    />
  ) : null;
};

export default connect(null, {
  setShowRightSidedBannerVisibility,
  setIsFetching,
  TrackInsuranceCardScan,
})(CardScanRoute);
