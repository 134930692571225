import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  ROUTES,
  getSignUpFlowList,
  getPagesListBeforeLastRoute,
} from '../constants/routes';
import { localStorageGet, localStorageSave } from '../utils/localStorageHelper';
import { UserContext } from '../utils/context';
import useCookieProcessing from './useCookieProcessing';
import { uiTypes } from '../constants';
import usePatientProfile from './usePatientProfile';
import {
  PATIENT_INSURANCE_STATUS,
  PATIENT_ONBOARDING_STATUS,
} from '../constants/patient';
import usePartnersAPI from './usePartnersAPI';
import { PARTNERS_TYPE } from '../constants/subscriptionStatus';

const useUserRouteCheck = ({ setIsFetching }) => {
  const { loggedIn } = React.useContext(UserContext);
  const [isValidPartner, setIsValidPartner] = useState(true);
  const [referringProvider, setReferringProvider] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { didCompleteSignUpFlow } = localStorageGet('didCompleteSignUpFlow');
  const { getCookieValue, getCookieKeyValuePair } = useCookieProcessing();
  const { patientProfile: patient } = usePatientProfile();
  const { getPartnersList } = usePartnersAPI();
  const isGenericSignupFlow = useSelector(
    (store) => store.uiReducer?.isGenericSignupFlow
  );

  async function determineUsersNextRoute() {
    try {
      localStorageSave('subscriptionStatus', {
        subscriptionStatus: 'optum',
      });
      localStorageSave('didCompleteSignUpFlow', {
        didCompleteSignUpFlow: true,
      });

      if (patient.onboarded === PATIENT_ONBOARDING_STATUS.NONE) {
        return history.replace(ROUTES.FIRST_APPT_CONFIRM);
      }

      if (
        patient.insurance_card_status === PATIENT_INSURANCE_STATUS.NONE ||
        patient.insurance_card_status === PATIENT_INSURANCE_STATUS.FAILED
      ) {
        return history.replace(ROUTES.INSURANCE_INFO);
      }

      if (
        (patient.insurance_card_status === PATIENT_INSURANCE_STATUS.VALID ||
          patient.insurance_card_status === PATIENT_INSURANCE_STATUS.SKIPPED) &&
        (patient.onboarded === PATIENT_ONBOARDING_STATUS.PENDING ||
          patient.onboarded === PATIENT_ONBOARDING_STATUS.FINISHED)
      ) {
        return history.replace(ROUTES.FIRST_APPT_OVERVIEW);
      }

      return history.replace(ROUTES.SIGN_IN);
    } catch (err) {
      console.log('error', err);
      localStorageSave('didCompleteSignUpFlow', {
        didCompleteSignUpFlow: false,
      });
      setIsFetching(false);
      return history.replace(ROUTES.NAME);
    } finally {
      setIsFetching(false);
    }
  }

  async function determineReferringPartner(partner) {
    const partnersList = await getPartnersList();
    const partnerFromList = partnersList.find(
      (item) => item.system_partner_id === partner
    );
    setIsValidPartner(Boolean(partnerFromList));
    localStorageSave('isReferringProvider', {
      isReferringProvider:
        partnerFromList?.type === PARTNERS_TYPE.REFERRING_PROVIDER,
    });
    setReferringProvider({
      found: partnerFromList?.type === PARTNERS_TYPE.REFERRING_PROVIDER,
      partner: partnerFromList,
    });
  }

  React.useEffect(() => {
    if (loggedIn && !patient) {
      setIsFetching(true);
    }

    if (
      patient &&
      loggedIn &&
      Boolean(loggedIn.isUserAccountBeingCreated) === false
    ) {
      determineUsersNextRoute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, patient]);

  // Save qstring cookie value into local storage
  React.useEffect(() => {
    // Save qstring cookie value into local storage
    localStorageSave('qstring', { qstring: '' });
    const qStringValue = getCookieKeyValuePair('qstring');
    if (qStringValue) {
      const extractedQString = getCookieValue(qStringValue);
      localStorageSave('qstring', {
        qstring: extractedQString,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check if partner param exists in URL/localStorage when user is not logged in
  // and determine if the partner is valid or not for the app redirecting to proper
  // sign-up flow. If referring provider is found then skip this side effect checking
  React.useEffect(() => {
    if (referringProvider) return;

    if (!loggedIn && !didCompleteSignUpFlow) {
      const { partner } = localStorageGet('partner');
      
      // Get partner name from URL if not find it in local storage
      let partnerName = partner || null;

      if (location.pathname.includes(ROUTES.NAME)) {
        partnerName = location.pathname.split('/')[2] || null;
      }

      // If partner name is not found in URL or local storage then persist in global
      // storage state that user is in generic sign-up flow
      if (!partnerName) {
        dispatch({
          type: uiTypes.CHANGE_SIGN_UP_FLOW,
          payload: true,
        });
        return;
      }

      // Otherwise, check if the partner is valid or not
      (async () => {
        setIsFetching(true);
        localStorageSave('partner', { partner: partnerName });
        await determineReferringPartner(partnerName);
        setIsFetching(false);
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referringProvider, didCompleteSignUpFlow, loggedIn, location.pathname]);

  // Set up sign-up flow based on referring provider or exisiting/not-available partner
  React.useEffect(() => {
    if (referringProvider === null) {
      return;
    }

    const { partner } = localStorageGet('partner');
    const isGenericSignupFlow = Boolean(!partner || referringProvider.found);

    dispatch({
      type: uiTypes.CHANGE_SIGN_UP_FLOW,
      payload: isGenericSignupFlow,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referringProvider]);

  // Check if user is on the name page and redirect to name page if partner is not valid
  React.useEffect(() => {
    if (
      !isValidPartner &&
      location.pathname.includes(ROUTES.NAME) &&
      location.pathname !== ROUTES.NAME
    ) {
      history.replace(ROUTES.NAME);
    }
  }, [history, isValidPartner, location.pathname]);

  // Check if user has completed sign-up flow and re-route to either first book appt or first insurance card
  // or last onboarding page if user tries navigating back
  React.useEffect(() => {
    const signUpFlowList = getSignUpFlowList(isGenericSignupFlow);
    const pagesBeforeLastPage = getPagesListBeforeLastRoute();
    if (
      loggedIn &&
      didCompleteSignUpFlow &&
      history.action === 'POP' &&
      (signUpFlowList.includes(location.pathname) ||
        pagesBeforeLastPage.includes(location.pathname))
    ) {
      // Re-route to first book appt page if user already completed sign up flow or
      // re-route to last book appt page if user tries navigating back from previous pages
      history.goForward();
    }

    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [location, didCompleteSignUpFlow, loggedIn, isGenericSignupFlow]);
};

export default useUserRouteCheck;
